import React from 'react'
import HeroNav from '../components/HeroNav'
import Footer from '../components/Footer'
import SeccionA from '../components/SeccionA'

export default ()=>{
    return (
        <>

         <HeroNav img="mayalav5.jpg" titulo="POR UN FUTURO LIMPIO" subtitulo="PRÓXIMAMENTE servicio a domicilio"/>
         <SeccionA/>
         <Footer/>

        </>
    )
}

