import React from 'react'
import BackgroundImg from 'gatsby-background-image'
import {graphql,useStaticQuery} from 'gatsby'
import NavBar from './NavBar'

export default (props) => {
  console.log(props.img)
  // const qry = `{ fluid: file(relativePath: {eq: "`
  // const qry2 = `"}) {
  //       childImageSharp {
  //         fluid(quality:100) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         } } } } `
  // const data2=qry+props.img+qry2
  // console.log(data2)
  // const data3=useStaticQuery(data2)
  // const data = useStaticQuery(graphql`
  //   { fluid: file(relativePath: {eq: "mayalav6.jpg"}) {
  //       childImageSharp {
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //        }
  //        }
  //        } 
  //  `)

const data = useStaticQuery(graphql`
    {
      fixed: file(relativePath: {eq: "mayalav01.jpg"}) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
      fluid: file(relativePath: {eq: "fondo4.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return (
        <BackgroundImg 
        className="text-center grid grid-cols-1 h-screen bg-center 
        items-center content-center" 
        fluid={data.fluid.childImageSharp.fluid} 
        >
        <NavBar color={props.color}/>
        <div>
          <h1 className="text-6xl text-sulphur text-blue-800 pb-8">{props.titulo}</h1>
          <h3 className="text-sm text-gray-800 text-3xl">{props.subtitulo}</h3>
          <p className="py-8 text-gray-800">{props.parrafo}</p>

        </div>
        {/* <p>{props.children}</p> */}
        </BackgroundImg>
    )
}